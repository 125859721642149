import { DCInput, PostVariantPayload, Variant } from '@adsk/offsite-dc-sdk';
import {
  PrintMessageDialog,
  ProductCustomizationForm,
  ProductDetails,
  productCustomizationTestIds,
  transformToVariantPayloadRfaOnly,
  usePrintMessageDialog,
} from '@mid-react-common/addins';
import { ModalContext, NotificationContext, PreviewWrapper } from '@mid-react-common/common';
import {
  ConfigurePreviewContainer,
  ConfigureProductCustomizationContainer,
  ConfigureScreenWrapper,
  ConfigureScrollableContainer,
  NameAndRelease,
  ProductCustomizationFooter,
  VariantFormState,
  VariantThumbnail,
  useProductCustomizationFooter,
  useProductCustomizationFormInRevit,
  useRepresentation,
} from '@mid-react-common/revit-components';
import Divider from '@mui/material/Divider';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ampli } from '../../../ampli';
import InsertFooter from '../../../components/InsertFooter/InsertFooter';
import DataContext from '../../../context/Data/Data.context';
import text from '../../../revit.text.json';
import useInsertInConfigureScreen from './useInsertInConfigureScreen';
import { useGenerateVariant } from './useGenerateVariant';
import { createVariant, getVariantFromLocalCache } from 'mid-addin-lib';
import { AccBridgeDownloadUrlQueryParams, AccBridgeSourceProjectDataQueryParams } from 'mid-api-services';
import { setDefaultInputValuesIfNotApplicable } from 'mid-utils';

// Amplitude event handler
const handleAmplitudeVariantsGenerateEvent = (postedVariant: Variant) => {
  ampli.rvtwVariantsGenerate({
    variantId: postedVariant.variantId,
    variantName: postedVariant.name,
    releaseNumber: postedVariant.release,
    projectId: postedVariant.tenancyId,
    productId: postedVariant.contentId,
    numberOfInputs: postedVariant.inputs.length,
  });
};

export const ConfigureScreen: React.FC = () => {
  const { isMessageDialogOpen, dialogMessage, showMessageDialog, closeMessageDialog } = usePrintMessageDialog();
  const { dontShowAgain, setModalState } = useContext(ModalContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);
  const {
    configurableProductProperties,
    currentProductRelease,
    selectedCachedVariant,
    setSelectedCachedVariant,
    resetConfigurableProductProperties,
    updateConfigurableProductInputs,
    variantFormState,
    setVariantFormState,
    setReFetchCachedVariants,
    selectedRepresentation,
    setSelectedRepresentation,
    productReleasesListLength,
    setPostedVariantIdsList,
    selectedIncomingBridgeDataQueryParams,
  } = useContext(DataContext);

  const [isFormInitializing, setIsFormInitializing] = useState<boolean>(true);
  const [savedVariant, setSavedVariant] = useState<Variant | undefined>();

  const incomingAccBridgeDataFromSourceProject = useMemo(
    (): AccBridgeSourceProjectDataQueryParams => ({
      sourceProjectId: selectedIncomingBridgeDataQueryParams?.sourceProjectId,
      sourceFolderUrn: selectedIncomingBridgeDataQueryParams?.sourceFolderUrn,
      targetProjectId: selectedIncomingBridgeDataQueryParams?.targetProjectId,
    }),
    [selectedIncomingBridgeDataQueryParams],
  );
  const incomingAccBridgeDataForDownloadUrl: AccBridgeDownloadUrlQueryParams = {
    sourceProjectId: selectedIncomingBridgeDataQueryParams?.sourceProjectId,
    targetProjectId: selectedIncomingBridgeDataQueryParams?.targetProjectId,
  };

  const setCurrentVariant = useCallback(
    (defaultVariant: Variant) => {
      setSavedVariant(defaultVariant);
      setSelectedCachedVariant(defaultVariant);
    },
    [setSavedVariant, setSelectedCachedVariant],
  );

  // data and functions required for ProductCustomizationForm
  const { productCustomizationFormError, isFormDataValid, setIsFormDataValid, inputsError, handleInputUpdate, formRules } =
    useProductCustomizationFormInRevit({
      currentProductRelease,
      configurableProductProperties,
      updateConfigurableProductInputs,
      setVariantFormState,
      logAndShowNotification,
      showMessageDialog,
      isFormInitializing,
      setIsFormInitializing,
    });

  const postVariant = useCallback(
    async (projectId: string, productId: string, release: number): Promise<Variant> => {
      // We set the default values for the inputs that are not applicable for the current inputs.
      // This makes sure that this variant gets cached no matter what the input value is for the inputs
      // that are not applicable.

      const inputPayload: DCInput[] = currentProductRelease?.inputs
        ? setDefaultInputValuesIfNotApplicable(configurableProductProperties.inputs, currentProductRelease.inputs)
        : configurableProductProperties.inputs;

      const postVariantPayload: PostVariantPayload = {
        ...transformToVariantPayloadRfaOnly(
          { ...configurableProductProperties, inputs: inputPayload },
          selectedRepresentation,
        ),
        release,
      };

      const cachedVariant = await getVariantFromLocalCache(projectId, productId, postVariantPayload, selectedRepresentation);
      if (cachedVariant) {
        setCurrentVariant(cachedVariant);
        return cachedVariant;
      }

      const createdVariant = await createVariant({
        projectId,
        productId,
        variantPayload: postVariantPayload,
        incomingAccBridgeData: incomingAccBridgeDataFromSourceProject,
      });

      setCurrentVariant(createdVariant);

      return createdVariant;
    },
    [
      configurableProductProperties,
      currentProductRelease?.inputs,
      incomingAccBridgeDataFromSourceProject,
      selectedRepresentation,
      setCurrentVariant,
    ],
  );

  const { handleGenerateNewVariantClick } = useGenerateVariant({
    currentProductRelease,
    selectedRepresentation,
    postVariant,
    setVariantFormState,
    setReFetchCachedVariants,
    dontShowAgain,
    setModalState,
    showNotification,
    handleAmplitudeVariantsGenerateEvent,
    setPostedVariantIdsList,
  });

  // data and functions required for ProductCustomizationFooter
  const { cachedVariantThumbnail, handleResetClick } = useProductCustomizationFooter({
    incomingAccBridgeData: incomingAccBridgeDataFromSourceProject,
    currentProductRelease,
    selectedCachedVariant,
    selectedRepresentation,
    setVariantFormState,
    resetConfigurableProductProperties,
    setSelectedCachedVariant,
    setIsFormInitializing,
  });

  // data and functions required for InsertFooter
  const { handleInsertRFA, polledVariant } = useInsertInConfigureScreen({
    incomingAccBridgeData: incomingAccBridgeDataFromSourceProject,
    currentProductRelease,
    selectedRepresentation,
    variantFormState,
    selectedCachedVariant,
    postVariant,
    setVariantFormState,
    setReFetchCachedVariants,
    setCurrentVariant,
    showNotification,
  });

  const handleRepresentationStateChange = (): void => {
    if (setVariantFormState) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);
    }
  };

  const {
    productReleaseRFAOutput,
    rfaRepresentationDropdownItems,
    representationDropdownItemsInNewVariantPage,
    selectedRepresentationDropdownItem,
    handleRfaRepresentationSelection,
  } = useRepresentation({
    configurableProductProperties,
    selectedRepresentation,
    setSelectedRepresentation,
    selectedVariant: selectedCachedVariant,
    handleRepresentationStateChange,
  });

  const { productCustomizationFormWrapper } = productCustomizationTestIds;

  return (
    <>
      <ConfigureScreenWrapper>
        <ConfigureProductCustomizationContainer data-testid={productCustomizationFormWrapper}>
          <ProductCustomizationForm
            inactive={isFormInitializing || variantFormState === VariantFormState.GENERATING_NEW_VARIANT}
            error={productCustomizationFormError}
            inputs={configurableProductProperties.inputs}
            inputsError={inputsError}
            handleInputUpdate={handleInputUpdate}
            setIsFormDataValid={setIsFormDataValid}
            formLayoutRules={formRules}
            isFormLoading={isFormInitializing}
          />
          <Divider />
          <ProductCustomizationFooter
            handleResetClick={handleResetClick}
            handleGenerateNewVariantClick={handleGenerateNewVariantClick}
            handleRfaRepresentationSelection={handleRfaRepresentationSelection}
            variantFormState={variantFormState}
            isFormDataValid={isFormDataValid}
            selectedRepresentationDropdownItem={selectedRepresentationDropdownItem}
            representationDropdownItems={rfaRepresentationDropdownItems}
            currentProductReleaseStatus={currentProductRelease?.status}
          />
        </ConfigureProductCustomizationContainer>
        <Divider orientation="vertical" />
        <ConfigurePreviewContainer>
          {currentProductRelease && (
            <NameAndRelease
              currentProductRelease={currentProductRelease}
              productReleasesListLength={productReleasesListLength}
            />
          )}
          <ConfigureScrollableContainer>
            <PreviewWrapper>
              <VariantThumbnail
                tenancyId={currentProductRelease?.tenancyId}
                incomingAccBridgeData={incomingAccBridgeDataForDownloadUrl}
                isLoading={variantFormState === VariantFormState.GENERATING_NEW_VARIANT}
                thumbnail={
                  polledVariant?.thumbnail ||
                  savedVariant?.thumbnail ||
                  cachedVariantThumbnail ||
                  currentProductRelease?.thumbnail
                }
                alternateMessage={
                  variantFormState === VariantFormState.GENERATING_NEW_VARIANT ? text.generatingNewVariant : undefined
                }
              />
            </PreviewWrapper>
            <ProductDetails
              title={text.productDetails}
              productCategory={productReleaseRFAOutput?.options?.category || ''}
              productFamily={productReleaseRFAOutput?.options?.family || ''}
              representations={productReleaseRFAOutput?.options?.modelStates || []}
              releaseNotes={currentProductRelease?.notes}
            />
          </ConfigureScrollableContainer>
          <InsertFooter
            handleInsertRFA={handleInsertRFA}
            variantFormState={variantFormState}
            isFormDataValid={isFormDataValid}
            representationDropdownItems={representationDropdownItemsInNewVariantPage}
            handleRfaRepresentationSelection={handleRfaRepresentationSelection}
            selectedRepresentationDropdownItem={selectedRepresentationDropdownItem}
          />
        </ConfigurePreviewContainer>
      </ConfigureScreenWrapper>
      <PrintMessageDialog isMessageDialogOpen={isMessageDialogOpen} closeMessageDialog={closeMessageDialog} isAddin>
        {dialogMessage}
      </PrintMessageDialog>
    </>
  );
};
